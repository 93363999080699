import { FaUserLarge, FaUserPlus } from "react-icons/fa6";
import LayoutMain from "../../components/LayoutMain";
import { useState } from "react";
import axios from "axios";
import messages from "../../utils/messages";
import ContactList from "./ContactList";
import { useSelector } from "react-redux";
import languages from "../../languages/languages";

export default function Contact() {
  const language = useSelector((state: any) => state.language);

  const [state, setState] = useState({
    name: "",
    userMailOrPhone: "",
    msg: "",
  });

  const [awareChild, setAwareChild] = useState("");

  const addContact = async () => {
    if (state.msg === messages.wait) return;

    setState({ ...state, msg: messages.wait });
    if (!state.name.trim()) {
      return setState({
        ...state,
        msg: languages[language.language.short].contact.s11,
      });
    }
    try {
      const { data } = await axios.put("/api/contact/add", {
        name: state.name,
        userMailOrPhone: state.userMailOrPhone,
      });

      if (!data.hasInternalAccount) {
        setState({
          ...state,
          msg: languages[language.language.short].contact.s4,
        });
      } else {
        setState({
          name: "",
          userMailOrPhone: "",
          msg: languages[language.language.short].contact.s6,
        });
        setAwareChild("" + Date.now());
      }
    } catch (error: any) {
      console.log(typeof error?.response?.data);
      if (error?.response?.data && typeof error.response.data === "string") {
        switch (error.response.data) {
          case "You cannot save yourself as your contact":
            setState({
              ...state,
              msg: languages[language.language.short].contact.s7,
            });
            break;
          case "not new contact":
            setState({
              ...state,
              msg: languages[language.language.short].contact.s8,
            });
            break;
          case "Invalid user mail or mobile phone number":
            setState({
              ...state,
              msg: languages[language.language.short].contact.s9,
            });
            break;
        }
      }
    }
  };

  return (
    <LayoutMain>
      <div className="w-full">
        <h3 className="text-gray-800  text-center bg-red my-8 flex w-full justify-center items-center gap-1 ">
          <FaUserLarge className="text-xl" />
          <span>{languages[language.language.short].contact.s1}</span>
        </h3>
        <div className="w-11/12 mx-auto max-w-96 cursor-pointer p-4 mb-4 bg-slate-200 rounded-lg">
          <p className="text-gray-600 text-base p-1">
            {languages[language.language.short].contact.s10}
          </p>
          <input
            className="text-center ltr-direction mt-6 bg-white border border-gray-300 w-full p-2.5 block text-blue-900 placeholder-gray-500 focus:placeholder-gray-600 text-sm rounded-lg focus:outline-none focus:ring focus:ring-blue-500"
            type="text"
            placeholder={languages[language.language.short].contact.s2}
            onChange={(e) => setState({ ...state, name: e.target.value })}
            value={state.name}
          />

          <input
            className="text-center ltr-direction my-5 bg-white border border-gray-300 w-full p-2.5 block text-blue-900 placeholder-gray-500 focus:placeholder-gray-600 text-sm rounded-lg focus:outline-none focus:ring focus:ring-blue-500"
            type="text"
            placeholder={languages[language.language.short].contact.s18}
            onChange={(e) =>
              setState({ ...state, userMailOrPhone: e.target.value })
            }
            value={state.userMailOrPhone}
          />
          <p>{state.msg}</p>
          <button
            type="submit"
            onClick={addContact}
            className="border flex justify-center items-center gap-2 hover:bg-gray-100 hover:text-teal-500 hover:border-teal-500 font-medium rounded-lg text-base px-5 py-2.5 me-2 mb-2 text-white bg-teal-500    w-full"
          >
            <FaUserPlus />
            {languages[language.language.short].contact.s12}
          </button>
        </div>
        <ContactList awareChange={awareChild} />
        <div className="h-60 w-full"> </div>
      </div>
    </LayoutMain>
  );
}
