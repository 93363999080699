import config from "../configs/config";
import languages from "./languagesGlob";
let lngs: any = {};

if (config.languages && config.languages.length)
  config.languages.forEach((lng) => {
    lngs[lng] = languages[lng];
  });
else lngs = { ...languages };

export default lngs;
