import { createSlice } from "@reduxjs/toolkit";
import languages from "../languages/languages";
import config from "../configs/config";

export const languageSlice = createSlice({
  name: "language",
  initialState: {
    language: {
      short:
        config.languages && config.languages.length && config.languages[0]
          ? config.languages[0]
          : "fa",
      rtl:
        config.languages && config.languages.length && config.languages[0]
          ? languages[config.languages[0]].rtl
          : true,
    },
    languageBox: "close",
  },
  reducers: {
    changeLanguage: (state, action) => {
      state.language = action.payload;
    },
    boxStatus: (state, action) => {
      state.languageBox = action.payload;
    },
  },
});

export const { changeLanguage, boxStatus } = languageSlice.actions;
export default languageSlice.reducer;

// export const descremntAsync = (amount) => (dispatch) => {
//   setTimeout(() => {
//     dispatch(decrement(amount));
//   }, 1000);
// };
