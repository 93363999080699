import axios from "axios";
import { useEffect, useReducer, useState } from "react";
import { transform } from "../../utils/persianTransform";
import loadingGIF from "../../themes/blue/images/loading2.gif";
import languages from "../../languages/languages";
import { useSelector } from "react-redux";
var shamsi = require("shamsi-date-converter");

export default function ListPayment() {
  const language = useSelector((state: any) => state.language);

  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  const [paymentsId, setPaymentsId] = useState<any>([]);
  const fetchData = async () => {
    try {
      let paymentsIdRes: any = await axios.get(
        `/api/charge/cash/user/list?page=${page}&size=10&a=${Math.random()}`
      );

      setPaymentsId(paymentsIdRes.data.data);
    } catch (error) {}
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const loadItems = async () => {
      try {
        setLoading(true);

        const paymentsIdRes: any = await axios.get(
          `/api/charge/cash/user/list?page=${page}&size=10&a=${Math.random()}`
        );

        console.log(paymentsIdRes.data.data);

        setPaymentsId((prevItems: any) => [
          ...prevItems,
          ...paymentsIdRes.data.data,
        ]);
        setLoading(false);
        if (paymentsIdRes.data.data.length === 0) {
          setHasMore(false);
        }
      } catch (error: any) {
        setHasMore(false);
        setLoading(false);
      }
    };

    loadItems();
  }, [page]);

  const handleScroll = () => {
    if (
      window.innerHeight + window.scrollY >=
        document.documentElement.scrollHeight - 5 &&
      hasMore &&
      !loading
    ) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [loading]);

  const [_, forceUpdate] = useReducer((x) => x + 1, 0);

  useEffect(() => {
    forceUpdate();
  }, [language]);

  const convertNumber = (number: any) => {
    if (languages[language.language.short] === "fa") {
      return transform.engNumberToPersian(number);
    }
    return number;
  };

  const renderWhen = (when: string) => {
    let dateSTR = when.split(" ");
    let date: any = dateSTR[0].split("/");
    let time = dateSTR[dateSTR.length - 1];

    let gregorianDateArray = shamsi.jalaliToGregorian(
      +date[0],
      +date[1],
      +date[2]
    );

    date[1] =
      gregorianDateArray[1] < 10
        ? "0" + gregorianDateArray[1]
        : gregorianDateArray[1];

    date[2] =
      gregorianDateArray[2] < 10
        ? "0" + gregorianDateArray[2]
        : gregorianDateArray[2];

    gregorianDateArray = gregorianDateArray.join("/");

    return language.language.short === "fa"
      ? transform.engNumberToPersian(when)
      : gregorianDateArray + " " + time;
  };

  function statusLang(status: string) {
    switch (status) {
      case "اولیه":
        return languages[language.language.short].chargePage.s24;

      case "خطا":
        return languages[language.language.short].chargePage.s23;
      case "تایید شده":
        return languages[language.language.short].chargePage.s22;
    }
  }

  function setBgColor(status: string) {
    switch (status) {
      case "اولیه":
        return " bg-gray-500";

      case "خطا":
        return " bg-red-500";
      case "تایید شده":
        return " bg-green-500";
    }
  }

  return (
    <div className="w-full max-w-lg mx-auto">
      <div className="w-full bg-gray-600 text-center mt-4 p-1 text-white max-w-lg">
        {languages[language.language.short].chargePage.s14}
      </div>

      {paymentsId.map((payDet: any) => (
        <div
          key={payDet.paymentId}
          className="w-full p-1 py-4 border-b border-dashed border-b-gray-400 "
        >
          <div className="w-full flex justify-between pt-1">
            <span>
              <span className="font-bold">
                {languages[language.language.short].chargePage.s15}:
              </span>{" "}
              {convertNumber(payDet.paymentId)}
            </span>
            <span
              className={
                "text-white round bg-gray-500 p-1 px-4" +
                setBgColor(payDet.status)
              }
            >
              {statusLang(payDet.status)}
            </span>
          </div>
          <p className="pt-1">
            <span className="font-bold">
              {languages[language.language.short].chargePage.s15}:{" "}
            </span>
            <span>{convertNumber(transform.convertMoney(payDet.amount))}</span>{" "}
            {languages[language.language.short].chargePage.s17}
          </p>
          <p className="pt-1">
            <span className="font-bold">
              {languages[language.language.short].chargePage.s20}:
            </span>{" "}
            <span className="inline-block ltr-direction">
              {renderWhen(payDet.createTime)}
            </span>
          </p>
          <p className="pt-1">
            <span className="font-bold">
              {languages[language.language.short].chargePage.s18}:
            </span>{" "}
            -
          </p>
          <p className="pt-1">
            <span className="font-bold">
              {languages[language.language.short].chargePage.s21}:
            </span>{" "}
            {payDet.description}
          </p>
        </div>
      ))}
      <div className="flex justify-center w-full mt-8">
        {loading && (
          <img
            className="inline-block mx-auto"
            src={loadingGIF}
            alt="در حال لود..."
          />
        )}
        {!hasMore && (
          <p className="text-center">
            {languages[language.language.short].chargePage.s19}
          </p>
        )}
      </div>
    </div>
  );
}
