import { FaAngleDown, FaMoneyBill1 } from "react-icons/fa6";
import LayoutMain from "../../components/LayoutMain";

import axios from "axios";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import languages from "../../languages/languages";
import Transaction from "./Transaction";
import { transform } from "../../utils/persianTransform";
import loadingGIF from "../../themes/blue/images/loading2.gif";
import Notification from "../../components/Notification/Notification";
import config from "../../configs/config";

export default function HomePage({ prompt, promptToInstall }: any) {
  const [customerList, setCustomerList] = useState([]);
  const language = useSelector((state: any) => state.language);
  const [user, setUser] = useState({
    username: "",
    name: "",
    uniqueName: "",
    profileAvatar: null,
    roles: [],
    creationDate: "",
    mainAccount: {
      accNumber: "",
      lastUpdate: "",
      balance: "0",
      unit: "",
      realTimeBalanceDepositUpdate: true,
    },
    phoneNumber: "",
    pinCodeSet: false,
    redisKey: "",
  });

  const [transactions, setTransactions] = useState<any>([]);

  const fetchData = async () => {
    try {
      let userResP: any = axios.get("/api/current/user");
      let customerP: any = axios.get("/api/alt-account/customer-list");

      const [userRes, customer, reportTransaction]: any = await Promise.all([
        userResP,
        customerP,
      ]);

      setUser(userRes.data);
      setCustomerList(customer.data.slice(0, 3));
    } catch (error) {
      // console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const renderAccountName = (altAccountMetaData: any, lang: any) => {
    let str = "";
    switch (altAccountMetaData.uniqueId) {
      case "113":
        str += languages[lang].dynamicWords.dollarAccountName;
        break;
      case "114":
        str += languages[lang].dynamicWords.euroAccountName;
        break;
      case "115":
        str += languages[lang].dynamicWords.dirhamAccountName;
        break;
      default:
        str += altAccountMetaData.name;
        break;
    }

    return str;
  };

  const renderAccountUnit = (altAccountMetaData: any, lang: any) => {
    let str = "";
    switch (altAccountMetaData.uniqueId) {
      case "113":
        str += languages[lang].dynamicWords.dollar;
        break;
      case "114":
        str += languages[lang].dynamicWords.euro;
        break;
      case "115":
        str += languages[lang].dynamicWords.dirham;
        break;
      default:
        str += altAccountMetaData.unit;
        break;
    }

    return str;
  };

  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  useEffect(() => {
    const loadItems = async () => {
      try {
        setLoading(true);

        const response: any = await axios.get(
          `/api/transaction/current-user/report?page=${page}&size=10&accounts=ALL&transactionType=inout&a=${Math.random()}`
        );

        console.log(response);

        setTransactions((prevItems: any) => [...prevItems, ...response.data]);
        setLoading(false);
        if (response.data.length === 0) {
          setHasMore(false);
        }
      } catch (error: any) {
        setHasMore(false);
        setLoading(false);
      }
    };

    loadItems();
  }, [page]);

  // const handleScroll = () => {
  //   if (
  //     window.innerHeight + document.documentElement.scrollTop !==
  //       document.documentElement.offsetHeight ||
  //     loading
  //   ) {
  //     return;
  //   }
  //   setPage((prevPage) => prevPage + 1);
  // };

  const handleScroll = () => {
    if (
      window.innerHeight + window.scrollY >=
        document.documentElement.scrollHeight - 5 &&
      hasMore &&
      !loading
    ) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [loading]);

  return (
    <LayoutMain>
      <>
        <div className="w-full">
          <Notification prompt={prompt} promptToInstall={promptToInstall} />
          <div className="mt-4 rounded-lg px-2 py-1 w-11/12 mx-auto shadow shadow-gray-300 hover:shadow-gray-600 relative">
            <h3 className="flex items-center  text-xl border-b border-b-gray-100 p-2">
              <FaMoneyBill1 className="text-2xl" />
              <span className="text-gray-600 p-2">
                {languages[language.language.short].mainAccount}
              </span>
              {language.language.rtl
                ? transform.convertMoney(user.mainAccount.balance, true)
                : transform.convertMoney(user.mainAccount.balance)}{" "}
              {
                languages[language.language.short].dynamicWords[
                  config.mainAccountUnit
                ]
              }
            </h3>
            <div className="w-full ">
              {customerList.map((item: any) => (
                <h3
                  key={item.altAccountMetaData.uniqueId}
                  className="mt-2 flex items-center  border-b border-b-gray-100 p-2 last:border-b-0"
                >
                  <FaMoneyBill1 className="text-xl" />
                  <span className="text-gray-600 p-2">
                    {renderAccountName(
                      item.altAccountMetaData,
                      language.language.short
                    )}
                  </span>
                  {language.language.rtl
                    ? transform.convertMoney(item.balance, true)
                    : transform.convertMoney(item.balance)}{" "}
                  {`${renderAccountUnit(
                    item.altAccountMetaData,
                    language.language.short
                  )}`}{" "}
                  {}
                </h3>
              ))}
            </div>
            <div
              className={
                customerList.length > 3
                  ? "absolute bottom-0 left-3 p-1 cursor-pointer text-xl text-gray-500"
                  : "hidden"
              }
            >
              <FaAngleDown />
            </div>
          </div>
        </div>
        {transactions.map((tran: any) => (
          <Transaction tran={{ ...tran }} page={page} />
        ))}
        <div className="flex justify-center w-full mt-8">
          {loading && (
            <img
              className="inline-block mx-auto"
              src={loadingGIF}
              alt="در حال لود..."
            />
          )}
          {!hasMore && <p className="text-center">تراکنش بیشتری وجود ندارد</p>}
        </div>

        <div className="h-60 w-full"> </div>
      </>
    </LayoutMain>
  );
}
