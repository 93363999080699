import LayoutMain from "../../components/LayoutMain";
import loadingGif from "../../themes/blue/images/loading.gif";
import { Calendar, CalendarProvider } from "zaman";
import { useEffect, useState } from "react";
import MultipleFileUploadField from "../../components/upload/MultipleFileUploadField";
import { useSelector } from "react-redux";
import axios from "axios";
import validator from "validator";
import languages from "../../languages/languages";

export default function AddDoc() {
  const language = useSelector((state: any) => state.language);

  const [calendarValueStart, setCalendarValueStart]: any = useState(() => {
    new Date();
  });
  const [showDate, setShowDate] = useState(false);

  const showDatepicker = () => {
    setShowDate(true);
  };
  const { nationalCard } = useSelector((state: any) => state.doc);
  const [page2, setPage2] = useState("nationalFront");
  const [page, setPage] = useState("nationalFront");

  const [loading, setLoading] = useState(true);

  const notify = (pg: string, step: string) => {
    setPage2(pg);
  };

  const stepNext = () => {
    switch (page) {
      case "nationalFront":
        setPage("nationalBack");
        break;
      case "nationalBack":
        setPage("birthCertificate");
        break;
      case "birthCertificate":
        setPage("passport");
        break;
      case "passport":
        setPage("showFields");
        break;
      case "passport2":
        setPage("showFields");
        break;

      case "foreignCard":
        setPage("passportForeign");
        break;

      case "passportForeign":
        setPage("showFields");
        break;

      case "manifest":
        setPage("newspaper");
        break;
      case "newspaper":
        setPage("foundedAd");
        break;
      case "foundedAd":
        setPage("stock");
        break;
      case "stock":
        setPage("showFields");
        break;
    }
  };

  const stepBack = () => {
    switch (page) {
      case "nationalBack":
        setPage("nationalFront");
        break;
      case "birthCertificate":
        setPage("nationalBack");
        break;
      case "passport":
        setPage("birthCertificate");
        break;
      case "showFields":
        setPage("passport");
        break;

      case "newspaper":
        setPage("manifest");
        break;
      case "passportForeign":
        setPage("foreignCard");
        break;
      case "foundedAd":
        setPage("newspaper");
        break;

      case "stock":
        setPage("foundedAd");
        break;
    }
  };

  useEffect(() => {
    setLoading(false);
    (async () => {
      const { data: userDetails } = await axios.get("/api/current/user");
      if (validator.isEmail(userDetails.username)) {
        setPage("manifest");
      }
      setLoading(false);
    })();
  }, []);

  return (
    <LayoutMain>
      {loading ? (
        <div className="flex justify-center items-center h-screen">
          <img className="-mt-20" src={loadingGif} alt="loading" />
        </div>
      ) : (
        <>
          <div className={page === "nationalFront" ? "w-full" : "hidden"}>
            <h3 className="text-center bg-red my-8">
              {languages[language.language.short].uploadPage.s1}
            </h3>
            <div className="w-11/12 mx-auto max-w-96 cursor-pointer border border-gray-300 rounded p-4 mb-4">
              <MultipleFileUploadField
                title={languages[language.language.short].uploadPage.s2}
                name="nationalFront"
                notify={notify}
              />
            </div>
            <p
              onClick={() => setPage("foreignCard")}
              className="text-center mb-6 text-sm  cursor-pointer"
            >
              {languages[language.language.short].uploadPage.s4}
            </p>
          </div>
          <div className={page === "nationalBack" ? "w-full" : "hidden"}>
            <h3 className="text-center bg-red my-8">
              {languages[language.language.short].uploadPage.s5}
            </h3>
            <div className="w-11/12 mx-auto max-w-96 cursor-pointer border border-gray-300 rounded p-4 mb-4">
              <MultipleFileUploadField
                title={languages[language.language.short].uploadPage.s6}
                name="nationalBack"
                notify={notify}
              />
            </div>
          </div>
          <div className={page === "birthCertificate" ? "w-full" : "hidden"}>
            <h3 className="text-center bg-red my-8">
              {languages[language.language.short].uploadPage.s7}
            </h3>
            <div className="w-11/12 mx-auto max-w-96 cursor-pointer border border-gray-300 rounded p-4 mb-4">
              <MultipleFileUploadField
                title={languages[language.language.short].uploadPage.s8}
                name="birthCertificate"
                notify={notify}
              />
            </div>
          </div>
          <div className={page === "passport" ? "w-full" : "hidden"}>
            <h3 className="text-center bg-red my-8">
              {languages[language.language.short].uploadPage.s9}
            </h3>
            <div className="w-11/12 mx-auto max-w-96 cursor-pointer border border-gray-300 rounded p-4 mb-4">
              <MultipleFileUploadField
                title={languages[language.language.short].uploadPage.s10}
                name="passport"
                notify={notify}
              />
            </div>
          </div>
          <div className={page === "foreignCard" ? "w-full" : "hidden"}>
            <h3 className="text-center bg-red my-8">
              {languages[language.language.short].uploadPage.s11}
            </h3>
            <div className="w-11/12 mx-auto max-w-96 cursor-pointer border border-gray-300 rounded p-4 mb-4">
              <MultipleFileUploadField
                title={languages[language.language.short].uploadPage.s12}
                name="foreignCard"
                notify={notify}
              />
            </div>
            <p
              onClick={() => setPage("nationalFront")}
              className="text-center mb-6 text-sm cursor-pointer"
            >
              title={languages[language.language.short].uploadPage.s13}
            </p>
          </div>
          <div className={page === "foreign" ? "w-full" : "hidden"}>
            <h3 className="text-center bg-red my-8">
              {languages[language.language.short].uploadPage.s14}
            </h3>
            <div className="w-11/12 mx-auto max-w-96 cursor-pointer border border-gray-300 rounded p-4 mb-4">
              <MultipleFileUploadField
                title={languages[language.language.short].uploadPage.s15}
                name="foreignCard"
                notify={notify}
              />
            </div>
            <p
              onClick={() => setPage("nationalFront")}
              className="text-center mb-6 text-sm cursor-pointer"
            >
              {languages[language.language.short].uploadPage.s16}
            </p>
          </div>
          <div className={page === "passportForeign" ? "w-full" : "hidden"}>
            <h3 className="text-center bg-red my-8">
              {languages[language.language.short].uploadPage.s17}
            </h3>
            <div className="w-11/12 mx-auto max-w-96 cursor-pointer border border-gray-300 rounded p-4 mb-4">
              <MultipleFileUploadField
                title={languages[language.language.short].uploadPage.s18}
                name="passportForeign"
                notify={notify}
              />
            </div>
          </div>
          <div className={page === "manifest" ? "w-full" : "hidden"}>
            <h3 className="text-center bg-red my-8">
              {languages[language.language.short].uploadPage.s19}
            </h3>
            <div className="w-11/12 mx-auto max-w-96 cursor-pointer border border-gray-300 rounded p-4 mb-4">
              <MultipleFileUploadField
                title={languages[language.language.short].uploadPage.s20}
                name="manifest"
                notify={notify}
              />
            </div>
          </div>
          <div className={page === "foundedAd" ? "w-full" : "hidden"}>
            <h3 className="text-center bg-red my-8">
              {languages[language.language.short].uploadPage.s21}
            </h3>
            <div className="w-11/12 mx-auto max-w-96 cursor-pointer border border-gray-300 rounded p-4 mb-4">
              <MultipleFileUploadField
                title={languages[language.language.short].uploadPage.s22}
                name="foundedAd"
                notify={notify}
              />
            </div>
          </div>
          <div className={page === "stock" ? "w-full" : "hidden"}>
            <h3 className="text-center bg-red my-8">
              {languages[language.language.short].uploadPage.s23}
            </h3>
            <div className="w-11/12 mx-auto max-w-96 cursor-pointer border border-gray-300 rounded p-4 mb-4">
              <MultipleFileUploadField
                title={languages[language.language.short].uploadPage.s24}
                name="stock"
                notify={notify}
              />
            </div>
          </div>
          <div className={page === "newspaper" ? "w-full" : "hidden"}>
            <h3 className="text-center bg-red my-8">
              {languages[language.language.short].uploadPage.s25}
            </h3>
            <div className="w-11/12 mx-auto max-w-96 cursor-pointer border border-gray-300 rounded p-4 mb-4">
              <MultipleFileUploadField
                title={languages[language.language.short].uploadPage.s26}
                name="newspaper"
                notify={notify}
              />
            </div>
          </div>
          <div className={page === "showFields" ? "w-full" : "hidden"}>
            <div className="w-11/12 mx-auto max-w-96 cursor-pointer rounded p-4  mb-4">
              <p className="text-gray-600 text-base p-1">
                {languages[language.language.short].uploadPage.s27}
              </p>
              <input
                className="text-center ltr-direction bg-white border border-gray-300 w-full p-2.5 block text-blue-900 placeholder-gray-500 focus:placeholder-gray-600 text-sm rounded-lg focus:outline-none focus:ring focus:ring-blue-500 letterWidest"
                type="text"
                alt="کد ملی"
                placeholder="XXXXXXXXXX"
                disabled
              />
            </div>
            <div className="w-11/12 mx-auto max-w-96 cursor-pointer rounded p-4  mb-4">
              <p className="text-gray-600 text-base p-1">
                {languages[language.language.short].uploadPage.s28}
              </p>
              <input
                onFocus={showDatepicker}
                className="text-center ltr-direction bg-white border border-gray-300 w-full p-2.5 block text-blue-900 placeholder-gray-500 focus:placeholder-gray-600 text-sm rounded-lg focus:outline-none focus:ring focus:ring-blue-500 letterWidest mb-1"
                type="text"
                disabled
                alt="کد ملی"
              />
              <div className={showDate ? "" : "hidden"}>
                <CalendarProvider accentColor="#14B8A6" locale={"fa"}>
                  <Calendar
                    defaultValue={calendarValueStart}
                    onChange={(day: any) =>
                      setCalendarValueStart(new Date(day))
                    }
                  />
                </CalendarProvider>
              </div>
            </div>
            <div className="w-11/12 mx-auto max-w-96 cursor-pointer rounded p-4  mb-4">
              <p className="text-gray-600 text-base p-1">
                {languages[language.language.short].uploadPage.s29}
              </p>
              <input
                className="text-center ltr-direction bg-white border border-gray-300 w-full p-2.5 block text-blue-900 placeholder-gray-500 focus:placeholder-gray-600 text-sm rounded-lg focus:outline-none focus:ring focus:ring-blue-500 letterWidest"
                type="text"
                alt="نام"
                disabled
              />
            </div>
            <div className="w-11/12 mx-auto max-w-96 cursor-pointer rounded p-4  mb-4">
              <p className="text-gray-600 text-base p-1">
                {languages[language.language.short].uploadPage.s30}
              </p>
              <input
                className="text-center ltr-direction bg-white border border-gray-300 w-full p-2.5 block text-blue-900 placeholder-gray-500 focus:placeholder-gray-600 text-sm rounded-lg focus:outline-none focus:ring focus:ring-blue-500 letterWidest"
                type="text"
                alt="نام خانوادگی"
                disabled
              />
            </div>
            <div className="w-11/12 mx-auto max-w-96 cursor-pointer rounded p-4  mb-10">
              <p className="text-gray-600 text-base p-1">
                {languages[language.language.short].uploadPage.s31}
              </p>
              <input
                className="text-center ltr-direction bg-white border border-gray-300 w-full p-2.5 block text-blue-900 placeholder-gray-500 focus:placeholder-gray-600 text-sm rounded-lg focus:outline-none focus:ring focus:ring-blue-500 letterWidest"
                type="text"
                alt="نام پدر"
                disabled
              />
            </div>
          </div>
          <div
            className={page === "showFields" ? "flex mt-1 flex-col mb-8" : ""}
          >
            <button
              type="button"
              onClick={stepNext}
              className="border   hover:font-bold hover:border-teal-500 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 text-white bg-teal-500 w-full"
            >
              {languages[language.language.short].uploadPage.s32}
            </button>
            <button
              onClick={stepBack}
              type="button"
              className={
                page === "nationalFront" ||
                page === "foreignCard" ||
                page == "manifest"
                  ? "hidden"
                  : "text-teal-500 bg-white border border-teal-500  hover:bg-teal-500 hover:text-white  rounded-lg text-sm px-5 py-2.5 me-2 mb-2  w-full"
              }
            >
              {languages[language.language.short].uploadPage.s33}
            </button>
          </div>
          <div className="h-60 w-full"> </div>
        </>
      )}
    </LayoutMain>
  );
}
