import { createSlice } from "@reduxjs/toolkit";

export const lightboxSlice = createSlice({
  name: "lightbox",
  initialState: {
    lightboxState: false,
    showUploadDocs: "close",
    accountId: "",
    level: 0,
    pinDet: {
      pin: "",
      msg: "",
      aware: "",
    },
  },
  reducers: {
    changeLightBoxState: (state, action) => {
      state.lightboxState = action.payload;
    },
    changeShowUploadDocsState: (state, action) => {
      state.showUploadDocs = action.payload;
    },
    setAccountId: (state, action) => {
      state.accountId = action.payload;
    },
    setLevel: (state, action) => {
      state.level = action.payload;
    },
    setPinDet: (state, action) => {
      state.pinDet = action.payload;
    },
  },
});

export const {
  changeLightBoxState,
  changeShowUploadDocsState,
  setAccountId,
  setLevel,
  setPinDet,
} = lightboxSlice.actions;
export default lightboxSlice.reducer;

// export const descremntAsync = (amount) => (dispatch) => {
//   setTimeout(() => {
//     dispatch(decrement(amount));
//   }, 1000);
// };
