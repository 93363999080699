import languagesGlobe from "../languages/languagesGlob";

let keys: any = [];
const iterateObject = (obj: any, msg: any) => {
  for (var key in obj) {
    if (msg === obj[key]) {
      keys.push(key);
      return true;
    }

    if (typeof obj[key] === "object" && obj[key] !== null) {
      const result: any = iterateObject(obj[key], msg); // متد بازگشتی برای آبجکت های تو در تو
      if (result) {
        keys.push(key);
        return true;
      }
    }
  }
  return null;
};

const translator = (msg: string, lang: string) => {
  keys = [];
  iterateObject(languagesGlobe, msg);
  const itemLocations = keys.reverse();

  if (itemLocations.length === 2) return languagesGlobe[lang][itemLocations[1]];

  if (itemLocations.length === 3)
    return languagesGlobe[lang][itemLocations[1]][itemLocations[2]];

  if (itemLocations.length === 4)
    return languagesGlobe[lang][itemLocations[1]][itemLocations[2]][
      itemLocations[3]
    ];
};

export default translator;
