import { FaLock, FaMixer, FaUserPlus } from "react-icons/fa6";
import LayoutMain from "../../components/LayoutMain";
import { useState } from "react";
import messages from "../../utils/messages";
import axios from "axios";
import languages from "../../languages/languages";
import { useSelector } from "react-redux";

export default function PinCode() {
  const language = useSelector((state: any) => state.language);

  const [state, setState] = useState({
    pinCode: "",
    password: "",
    msg: "",
  });

  const setPincode = async () => {
    if (state.msg === messages.wait) return;
    setState({ ...state, msg: messages.wait });
    if (state.pinCode.length !== 5) {
      return setState({
        ...state,
        msg: languages[language.language.short].pagePinCode.s1,
      });
    }

    try {
      await axios.post("/api/profile/pinCode-setup", {
        pinCode: state.pinCode,
        password: state.password,
      });

      setState({
        ...state,
        msg: languages[language.language.short].pagePinCode.s2,
      });
    } catch (error) {
      setState({
        ...state,
        msg: languages[language.language.short].pagePinCode.s3,
      });
    }
  };

  return (
    <LayoutMain>
      <div className="w-full">
        <h3 className="text-center bg-red my-8">
          {languages[language.language.short].pagePinCode.s4}
        </h3>

        <div className="bg-white border border-gray-300 w-11/12 flex items-center ltr-direction text-blue-900 placeholder-gray-500 focus:placeholder-gray-600 text-sm rounded-lg focus:outline-none focus:ring focus:ring-blue-500 max-w-md mx-auto radious">
          <div className="p-2">
            <FaLock className="text-2xl" />
          </div>
          <input
            type="password"
            placeholder={languages[language.language.short].pagePinCode.s5}
            className="bg-white border-0 border-gray-300 w-full p-1 block outline-none rounded text-center text-gray-700"
            onChange={(e) => setState({ ...state, password: e.target.value })}
            value={state.password}
          />
        </div>
        <div className="bg-white border mt-8 border-gray-300 w-11/12 flex items-center ltr-direction text-blue-900 placeholder-gray-500 focus:placeholder-gray-600 text-sm rounded-lg focus:outline-none focus:ring focus:ring-blue-500 max-w-md mx-auto radious">
          <div className="p-2">
            <FaMixer className="text-2xl" />
          </div>
          <input
            type="password"
            className="bg-white border-0 border-gray-300 w-full p-1 block outline-none rounded text-center text-gray-500"
            onChange={(e) => setState({ ...state, pinCode: e.target.value })}
            placeholder={languages[language.language.short].pagePinCode.s6}
            value={state.pinCode}
          />
        </div>
        <p className="text-center mt-4">{state.msg}</p>
        <button
          type="submit"
          onClick={setPincode}
          className="border  flex justify-center items-center gap-2 hover:bg-gray-100 hover:text-teal-500 hover:border-teal-500 font-medium rounded-lg text-base px-5 py-2.5  mb-2 text-white bg-teal-500 w-full max-w-md mx-auto"
        >
          <FaUserPlus />
          {languages[language.language.short].pagePinCode.s7}
        </button>
      </div>
    </LayoutMain>
  );
}
