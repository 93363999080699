const config = {
  domainName: "yee",
  siteTitle: "راس مال",
  languages: ["ar", "en"],
  international: true,
  oldUrl: "",
  url: "http://172.31.125.176:9000",
  bankAccountNumber: "???",
  bankAccountName: "???",
  mainAccountUnit: "rialYemen",
  defaultCoutnry: "ye",
};

export default config;