import { useCallback, useState } from "react";
import { FileRejection, useDropzone, FileError } from "react-dropzone";
import { SingleFileUploadWithProgress } from "./SingleFileUploadWithProgress";

import nationalCardFront from "../../themes/blue/images/nationalCardFront.jpg";
import nationalCardBack from "../../themes/blue/images/nationalCardBack.png";
import birthCertificate from "../../themes/blue/images/birthCertificate.jpg";
import foreignCard from "../../themes/blue/images/foreignCard.jpg";
import passport from "../../themes/blue/images/passport.jpg";
import manifest from "../../themes/blue/images/manifest.jpg";
import passportForeign from "../../themes/blue/images/passport.jpg";
import newspaper from "../../themes/blue/images/newspaper.jpg";
import xlsx from "../../themes/blue/images/xlsx.jpg";

import placeHolderPic from "../../themes/blue/images/placeholder.jpg";

export interface UploadableFile {
  id: number;
  file: File;
  errors: FileError[];
  url?: string;
}

export default function MultipleFileUploadField({
  title,
  name,
  notify,
}: {
  title: string;
  name: string;
  notify: (pg: string, step: string) => void;
}) {
  const [file, setFile] = useState<any>([]);
  const [uploadedURL, setUploadedURL] = useState<string>("");

  const onDrop = useCallback((acceptedFiles: File[]) => {
    // Do something with the files

    setFile(acceptedFiles);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false,
  });

  function onDelete(file: File) {
    //   setFiles((curr) => curr.filter((fw) => fw.file !== file));
  }

  function onUpload(url: string) {
    notify(name, "forward");
    setUploadedURL(url);
  }

  let placeholderImage: any;
  switch (name) {
    case "xlsx":
      placeholderImage = xlsx;
      break;
    case "nationalFront":
      placeholderImage = nationalCardFront;
      break;
    case "nationalBack":
      placeholderImage = nationalCardBack;
      break;
    case "birthCertificate":
      placeholderImage = birthCertificate;
      break;
    case "passport":
      placeholderImage = passport;
      break;
    case "passportForeign":
      placeholderImage = passportForeign;
      break;
    case "manifest":
      placeholderImage = manifest;
      break;
    case "newspaper":
      placeholderImage = newspaper;
      break;
    case "foreignCard":
      placeholderImage = foreignCard;
      break;
    case "foundedAd":
      placeholderImage = placeHolderPic;
      break;
    case "stock":
      placeholderImage = placeHolderPic;
      break;
  }

  return (
    <>
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        <img
          src={uploadedURL ? `${uploadedURL}` : `${placeholderImage}`}
          className="w-full"
          alt="تصویر کارت ملی خود را بارگذاری کنید"
        />
        {isDragActive ? (
          <p className="mt-3 text-center text-sm">رها کنید!</p>
        ) : (
          <p className="mt-3 text-center text-sm">{title}</p>
        )}
      </div>
      {(file && file.length && (
        <SingleFileUploadWithProgress
          file={file[0]}
          onDelete={onDelete}
          onUpload={onUpload}
          name={name}
        />
      )) ||
        null}
      {/* {JSON.stringify(file)} */}
    </>
  );
}
