import axios from "axios";
import { useEffect, useState } from "react";
import { FaTimes } from "react-icons/fa";
import pasargard from "../../themes/blue/images/bnk_bpi.png";
import {
  FaArrowRightArrowLeft,
  FaChartLine,
  FaCheck,
  FaMinus,
  FaPenToSquare,
  FaTrash,
} from "react-icons/fa6";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  changeLightBoxState,
  changeShowUploadDocsState,
  setAccountId,
  setLevel,
} from "../../redux/lightboxSlice";
import languages from "../../languages/languages";

export default function IBANList({ awareChange }: { awareChange: string }) {
  const language = useSelector((state: any) => state.language);

  const { lightboxState } = useSelector((state: any) => state.lightbox);

  const dispatch = useDispatch();

  const [ibans, setIBANS] = useState([]);

  const fetchData = async () => {
    try {
      const { data } = await axios.get("/api/userBankAccount/list/1/10");
      console.log(data);
      setIBANS(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    (async () => {
      fetchData();
    })();
  }, [awareChange]);

  const [deleteItem, setDeleteItem] = useState("");

  const deleteIBAN = async (dbid: any) => {
    try {
      await axios.delete(`/api/userBankAccount/delete/${dbid}`);
      setDeleteItem("");
      setEditIBANId("");
      fetchData();
    } catch (error) {
      console.log(error);
    }
  };

  const [editIBANId, setEditIBANId] = useState("");

  const [editState, setEditState] = useState({ name: "", iban: "", msg: "" });
  const editIBAN = async (dbid: number) => {
    await axios.post("/api/userBankAccount/edit", {
      name: editState.name,
      accountId: editIBANId,
      dbid,
    });

    await fetchData();
    setEditIBANId("");
  };
  const navigate = useNavigate();
  const goToIncreaseBalance = async (accountId: any, level: number) => {
    try {
      dispatch(setAccountId(accountId));
      dispatch(setLevel(level));
      dispatch(changeLightBoxState(true));

      if (level === 1) {
        await axios.post("/api/userBankAccount/request/level2", {
          accountId,
        });
        return;
      }

      if (level === 0) {
        await axios.post(
          "/api/userBankAccount/request/level1/send-verification-code",
          {
            accountId,
          }
        );
      }
    } catch (error: any) {
      const { data } = error.response;
      dispatch(changeShowUploadDocsState("increaseCode"));
      switch (data) {
        case "GO LOGIN":
          navigate("/");
          break;
        case "user has not an accepted document":
          dispatch(changeShowUploadDocsState("docs"));
          break;
      }
    }
  };

  return (
    <div className="my-8 ">
      {ibans.map((contact: any, index: number) => (
        <div className="flex mx-auto border border-gray-300 rounded mb-4 w-11/12 relative">
          <div
            className={
              deleteItem === contact.accountId
                ? "absolute inset-0 bg-gray-800 bg-opacity-80 flex justify-center items-center content-center flex-col rounded"
                : "hidden"
            }
          >
            <h2 className="text-white">
              {languages[language.language.short].bankAccount.s13}
            </h2>
            <div className="flex gap-6 mt-2">
              <button
                type="button"
                className="bg-teal-500 px-6 py-1 rounded text-white"
                onClick={() => {
                  deleteIBAN(contact.dbid);
                  setEditIBANId("");
                }}
              >
                {languages[language.language.short].bankAccount.s14}
              </button>
              <button
                type="button"
                className="bg-teal-400 text-white px-6 py-1 rounded"
                onClick={() => {
                  setDeleteItem("");
                  setEditIBANId("");
                }}
              >
                {languages[language.language.short].bankAccount.s15}
              </button>
            </div>
          </div>
          <div className="myPic w-24 h-24 p-1 flex items-center">
            <img src={contact.bankLogo} className="rounded" alt="bank" />
          </div>
          <div className="myCenter flex flex-col justify-between w-full">
            <div className="flex gap-2">
              {editIBANId === contact.accountId ? (
                <input
                  type="text"
                  value={editState.name}
                  autoFocus={true}
                  onChange={(e: any) =>
                    setEditState({ ...editState, name: e.target.value })
                  }
                  className="p-1 bg-white border border-gray-300 w-full  block text-blue-900 placeholder-gray-500 focus:placeholder-gray-600 text-sm rounded-lg focus:outline-none focus:ring focus:ring-blue-500"
                />
              ) : (
                <h2 className="p-1 text-sm whitespace-nowrap text-ellipsis overflow-hidden">
                  {contact.name}
                </h2>
              )}
            </div>
            {editIBANId === contact.accountId ? (
              <input
                type="text"
                value={
                  editState.iban.startsWith("IR")
                    ? editState.iban
                    : "IR" + editState.iban
                }
                onChange={(e: any) =>
                  setEditState({ ...editState, iban: e.target.value })
                }
                className="p-1 bg-white border border-gray-300 w-full  block text-blue-900 placeholder-gray-500 focus:placeholder-gray-600 text-sm rounded-lg focus:outline-none focus:ring focus:ring-blue-500"
              />
            ) : (
              <h2 className="p-1 text-sm whitespace-nowrap text-ellipsis overflow-hidden">
                {contact.accountId.startsWith("IR")
                  ? contact.accountId
                  : "IR" + contact.accountId}
              </h2>
            )}

            <h2 className="p-1 text-sm whitespace-nowrap text-ellipsis overflow-hidden">
              {languages[language.language.short].bankAccount.s11}{" "}
              {contact.level === 0
                ? " 5 "
                : contact.level === 1
                ? " 50 "
                : contact.level === 2
                ? "نامحدود"
                : ""}{" "}
              {languages[language.language.short].bankAccount.s12}
            </h2>
          </div>
          <div className="myLeft flex flex-col justify-around item">
            {editIBANId === contact.accountId ? (
              <div className="my-left-top  justify-start  h-full gap-4 flex items  flex-col p-2">
                <FaCheck
                  className="cursor-pointer text-gray-500"
                  onClick={() => editIBAN(contact.dbid)}
                />
                <FaTimes
                  className="cursor-pointer text-gray-500"
                  onClick={() => setEditIBANId("")}
                />
              </div>
            ) : (
              <>
                <div className="my-left-top w- justify-center flex items gap-4">
                  <FaPenToSquare
                    className="cursor-pointer text-gray-500"
                    onClick={() => {
                      setEditIBANId(contact.accountId);
                      setEditState({
                        ...editState,
                        name: contact.name,
                        iban: contact.accountId,
                      });
                      setDeleteItem("");
                    }}
                  />
                  <FaTrash
                    className="cursor-pointer text-gray-500"
                    onClick={() => setDeleteItem(contact.accountId)}
                  />
                </div>
                <div className="my-left-top w-16 flex justify-center gap-3">
                  <Link
                    to={`/transaction/withdraw/${
                      contact.accountId
                    }/${contact.name.replace(/ /g, "-")}`}
                  >
                    <FaArrowRightArrowLeft className="cursor-pointer text-gray-500" />
                  </Link>
                  <FaChartLine
                    className="cursor-pointer text-gray-500"
                    onClick={() =>
                      goToIncreaseBalance(contact.accountId, contact.level)
                    }
                  />
                </div>
              </>
            )}
          </div>
        </div>
      ))}
      <div className="mb-8"></div>
    </div>
  );
}
