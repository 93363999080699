import { createSlice } from "@reduxjs/toolkit";

export const docSlice = createSlice({
  name: "doc",
  initialState: {
    nationalCard: {
      uploadMSG: "hiddenNationalCardBox",
      msg: "",
      nationalCode: "",
      brithDate: "",
      name: "",
      family: "",
      fatherName: "",
    },
  },
  reducers: {
    nationalCardUploadMSG: (state, action) => {
      state.nationalCard.uploadMSG = action.payload;
    },
  },
});

export const { nationalCardUploadMSG } = docSlice.actions;
export default docSlice.reducer;

// export const descremntAsync = (amount) => (dispatch) => {
//   setTimeout(() => {
//     dispatch(decrement(amount));
//   }, 1000);
// };
