export interface FileHeaderProps {
  file: File;
  onDelete: (file: File) => void;
}

export default function FileHeader({ file, onDelete }: FileHeaderProps) {
  return (
    <>
      {/* <p>{file?.name}</p>
      <button type="button" onClick={() => onDelete(file)}>
        Delete
      </button> */}
    </>
  );
}
