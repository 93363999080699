import { useEffect, useState } from "react";
import { FaTimes } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { changeSideMenuState } from "../redux/sideMenuSlice";
import languages from "../languages/languages";
import axios from "axios";
const duration = 150;

export default function SideMenu() {
  const dispatch = useDispatch();
  const { sideMenuState } = useSelector((state: any) => state.sideMenu);

  const language = useSelector((state: any) => state.language);

  const [user, setUser] = useState<any>({
    username: "",
    roles: [],
  });

  const fetchData = async () => {
    try {
      let userRes: any = await axios.get("/api/current/user");

      setUser(userRes.data);
    } catch (error) {}
  };

  useEffect(() => {
    fetchData();
  }, []);
  return (
    <div className={sideMenuState === "open" ? "w-full" : "hidden"}>
      <div
        className="fixed bottom-0 right-0 left-0 top-0 z-50 bg-gray-700/50"
        onClick={() => dispatch(changeSideMenuState("close"))}
      >
        <div
          className="flex justify-center flex-col w-full max-w-2xl mx-auto relative "
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div
            className={
              language.language.rtl
                ? "absolute top-0 right-0 bottom-0 w-11/12 max-w-80 bg-gray-100 h-screen"
                : "absolute top-0 left-0 bottom-0 w-11/12 max-w-80 bg-gray-100  h-screen"
            }
          >
            <div className="w-full bg-teal-600 h-8 flex items-center p-2 text-white">
              <FaTimes
                className="cursor-pointer"
                onClick={() => dispatch(changeSideMenuState("close"))}
              />
            </div>
            <ul
              className="relative"
              onClick={() => dispatch(changeSideMenuState("close"))}
            >
              <li>
                <Link
                  className="block p-2 border-b border-b-slate-300"
                  to="/home"
                >
                  {languages[language.language.short].menu.s1}
                </Link>
              </li>
              <li>
                <Link
                  className="block p-2 border-b border-b-slate-300"
                  to="/contacts"
                >
                  {languages[language.language.short].menu.s2}
                </Link>
              </li>
              <li>
                <Link
                  className="block p-2 border-b border-b-slate-300"
                  to="/bank-accounts"
                >
                  {languages[language.language.short].menu.s3}
                </Link>
              </li>
              <li>
                <Link
                  className="block p-2 border-b border-b-slate-300"
                  to="/doc/add-doc"
                >
                  {languages[language.language.short].menu.s4}
                </Link>
              </li>
              <li>
                <Link
                  className="block p-2 border-b border-b-slate-300"
                  to="/pincode/set"
                >
                  {languages[language.language.short].menu.s5}
                </Link>
              </li>
              <li
                className={
                  user.roles.includes("ALT_WITHDRAW_REPORTER")
                    ? "block"
                    : "hidden"
                }
              >
                <Link
                  className="block p-2 border-b border-b-slate-300"
                  to="/transaction/subaccount-withdrawals"
                >
                  {languages[language.language.short].menu.s6}
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
