import { createSlice } from "@reduxjs/toolkit";

export const pwaSlice = createSlice({
  name: "pwa",
  initialState: {
    showPWAInstallButton: false,
  },
  reducers: {
    setShowPWAInstalledButton: (state, action) => {
      state.showPWAInstallButton = action.payload;
    },
  },
});

export const { setShowPWAInstalledButton } = pwaSlice.actions;
export default pwaSlice.reducer;

// export const descremntAsync = (amount) => (dispatch) => {
//   setTimeout(() => {
//     dispatch(decrement(amount));
//   }, 1000);
// };
