import { useState } from "react";
import messages from "../../utils/messages";
import LayoutMain from "../../components/LayoutMain";
import axios from "axios";

export default function SubAccountWithdrawals() {
  const [state, setState] = useState({
    start: "",
    end: "",
    withDrawsData: [],
  });

  const [msg, setMSG] = useState("");

  const getWithDraws = async () => {
    if (msg === messages.wait) return;

    setMSG(messages.wait);
    try {
      const buildUrl = `/api/temp-alt-withdraw/admin/list?start=${state.start}&end=${state.end}`;
      const res = await axios.get(buildUrl);

      setState({ ...state, withDrawsData: res.data });
      setMSG("");
    } catch (error) {
      setMSG("پارامتر صحیح نیست");
    }
  };
  return (
    <LayoutMain>
      <div className="w-full">
        <label
          htmlFor="success"
          className="block mb-2 text-sm font-medium text-gray-500 mt-4"
        >
          تاریخ شروع
        </label>
        <input
          type="text"
          id="success3"
          placeholder="1403/03/07"
          className="bg-white ltr-direction border text-right border-gray-300 w-full p-2.5 block text-blue-900 placeholder-gray-500 focus:placeholder-gray-600 text-sm rounded-lg focus:outline-none focus:ring focus:ring-blue-500"
          onChange={(e) => setState({ ...state, start: e.target.value })}
          value={state.start}
        />
        <label
          htmlFor="success"
          className="block mb-2 text-sm font-medium text-gray-500 mt-4"
        >
          تاریخ پایان
        </label>
        <input
          type="text"
          id="success3"
          placeholder="1403/08/18"
          className="bg-white ltr-direction text-right border border-gray-300 w-full p-2.5 block text-blue-900 placeholder-gray-500 focus:placeholder-gray-600 text-sm rounded-lg focus:outline-none focus:ring focus:ring-blue-500"
          onChange={(e) => setState({ ...state, end: e.target.value })}
          value={state.end}
        />
        <p className="text-center text-gray-700 mt-8">{msg}</p>
        <button
          type="submit"
          onClick={getWithDraws}
          className="border  max-w-72  block mx-auto  hover:bg-teal-500  hover:border-teal-500 font-medium rounded-lg text-sm px-5 py-2.5  mb-2 text-white bg-teal-400    w-full"
        >
          مشاهده
        </button>
        {state.withDrawsData.map((item: any) => (
          <div
            className="w-full border-b-2 border-b-gray-600"
            dangerouslySetInnerHTML={{
              __html: JSON.stringify(item).replace(/,/gi, "<br />"),
            }}
          ></div>
        ))}
      </div>
    </LayoutMain>
  );
}
