import { createSlice } from "@reduxjs/toolkit";

export const sideMenuSlice = createSlice({
  name: "sideMenu",
  initialState: {
    sideMenuState: "close",
  },
  reducers: {
    changeSideMenuState: (state, action) => {
      state.sideMenuState = action.payload;
    },
  },
});

export const { changeSideMenuState } = sideMenuSlice.actions;
export default sideMenuSlice.reducer;

// export const descremntAsync = (amount) => (dispatch) => {
//   setTimeout(() => {
//     dispatch(decrement(amount));
//   }, 1000);
// };
